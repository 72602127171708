import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_reroll.jpg"
            alt="Reroll guide"
          />
        </div>
        <div className="page-details">
          <h1>Eversoul Reroll guide</h1>
          <h2>How to reroll in Eversoul and start strong!</h2>
          <p>
            Last updated: <strong>09/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Is it worth to reroll?" />
        <p>
          While normally rerolling in idle games is not worth the effort due to
          dupes playing an important part in character strength, Eversoul makes
          it worth it by having certain characters work extremely well even with
          only one copy.
        </p>
        <p>
          Make sure to login as Guest - this reroll guide works only when you
          use that option (when you're on the home screen, the guest option is
          available in the top-left corner of the screen).
        </p>
        <p>
          Rerolling in Eversoul can be divided into two parts - Infinite Banner
          Reroll and Full Reroll.
        </p>
        <SectionHeader title="Freebies" />
        <p>
          Eversoul is pretty generous game, and during the first few days you
          will obtain a lot of Epics for free. Here's the list of the freebies:
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="mica" enablePopover />
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="clara" enablePopover />
          <EversoulCharacter mode="icon" slug="rebecca" enablePopover />
          <EversoulCharacter mode="icon" slug="seeha" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
        </div>
        <ul>
          <li>
            <strong>Linzy</strong> - you will get her during the tutorial but
            also she's the reward for clearing Chapter 6 (so you get 2 copies of
            her really early),
          </li>
          <li>
            <strong>Talia</strong> - given after you spend at least 1$ in the
            in-game shop,
          </li>
          <li>
            <strong>Chloe</strong> - reward for clearing Chapter 2,
          </li>
          <li>
            <strong>Clara</strong> - reward for clearing Chapter 3,
          </li>
          <li>
            <strong>Rebecca</strong> - reward for clearing Chapter 5,
          </li>
          <li>
            <strong>Seeha</strong> - reward from Boot Camp missions,
          </li>
          <li>
            <strong>Mephistopeles</strong> - login calendar (day 7) & Prelude
            Episode 6 Clear.
          </li>
        </ul>
        <p>
          In Japanese Version of Eversoul, Boot Camp Missions reward has been
          changed to <strong>Petra</strong>.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
        </div>
        <p>
          Just keep in in mind, that you need 8 dupes (14 dupes for Angel/Demon
          factions) in total to max a character - so even if you pull a dupe, it
          isn't the end of the world.
        </p>
        <SectionHeader title="Infinite banner" />
        <p>
          Infinite banner is unlocked after you finish the tutorial, and the way
          it works is you 10 pull the banner and it gives you 1 Epic (excluding
          Angel/Demon characters), and you can continue pulling this banner
          until you get a character of your choice and then select it. Earlier
          this banner used to have quite a lot of options to pick from such as
          Honglan, Vivienne etc – But now there is only one choice players must
          go for – Daphne. Daphne works extremely well at Epic ascensions and
          can single handedly bring down content difficulty by 30-40% allowing
          for a much easier and faster progress.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
        </div>
        <p>
          After Infinite Banner is done, you can start playing the game normally
          and this marks the end of the Quick Reroll process, but if you are
          planning to put in little more effort to get the best possible start –
          you perform a full reroll.
        </p>
        <p>
          In Japanse Version of Eversoul, initially players can reroll for one
          of the following characters - Vivienne, Talia, Aki, Honglan (as and
          when they are added) until Daphne is released.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
        </div>
        <SectionHeader title="Full reroll" />
        <p>
          Once you’ve unlocked the menus, you can click on the three lines icon
          in the top right of the screen and open up the Hall of Fame. This will
          give you a crazy amount of summoning currency that is always claimable
          on a new account - everyone gets to benefit from the progress of
          others! (Roughly 150 pulls at the time of writing this guide) and
          claiming other free pulls from ongoing events and logins.
        </p>
        <p>Now you have further two choices with the Everstones claimed:</p>
        <ul>
          <li>Rerolling in Standard Banner.</li>
          <li>Pushing to 2-1 to Unlock Pickup Choice Summon.</li>
        </ul>
        <p>
          In first choice you roll Standard Banner (10 Blue Tickets/2100
          Everstones per Multi) and aim for one of the following characters (One
          copy is enough):
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
        </div>
        <ul>
          <li>
            Yuria (Angel) – By far the best roll you can get as a newbie, She is
            the only SSS tier unit across all content whether early game or end
            game. Bear in mind Yuria herself does no damage at all as all her
            skills are of Sustain/Buffer nature, including her basic attack
            which makes surviving higher deficits much easier. Yuria + Daphne is
            the ultimate newbie start.
          </li>
          <li>
            Ayame (Demon) – While nothing really compares to getting a Yuria,
            Ayame is the next best option in Angel/Demon characters that is
            usable at lower ascensions. Ayame kit features Enemy Mana Reduction,
            Self-Mana Regeneration while inflicting debuffs such as bind, charm,
            attack / defense / speed down to the enemies and increasing the
            damage done by your team. But that is not all, Ayame has one of the
            highest attack scaling in the game and can function very well as a
            DPS. Furthermore, Ayame copies are farmable through Arena coins,
            thus making her one of easier Angel/Demon characters to origin down
            the lane.
          </li>
          <li>
            Honglan – Originally the best Infinite Banner Reroll Pick, she works
            very well as a support at lower investment, and can be built into an
            amazing carry later on. She inflicts speed debuff on enemies and
            drains their mana allowing your team to use its main skills faster
            than the enemy, which more times than not settles the match in your
            favor.
          </li>
          <li>
            Vivienne - A decent choice, similar to Honglan she causes your team
            to act faster than the enemy via her teamwide speed buff, and her
            damage is respectable.
          </li>
          <li>
            Talia - A great choice as a buffer/dps hybrid, she also charges the
            mana of your team and has heals, shields, and a defense passive. Can
            carry you in the early game with her ultimate, however other DPS
            options are more consistent late game. (Obtainable for free if you
            spend 1$ in-game on anything)
          </li>
        </ul>
        <h5>Pickup Choice Summon</h5>
        <StaticImage
          src="../../../images/eversoul/generic/reroll2.jpg"
          alt="Guide"
        />
        <p>Unlocks after completing Battlefront 2-1. </p>
        <p>
          A new feature that was added with the half anniversary, the Choice
          banner is meant to help new players catch up by giving them a
          selection banner to pull on at the start of the game. While this
          banner features 6 characters you can choose (Mephistopheles, Jiho,
          Aki, Velanna, Erika and Honglan) there are only 2 real choices new
          players should make:
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
        </div>
        <ul>
          <li>
            Aki is one of the best Hypercarries (aka scale extremely well with
            investment) in game and as such is what we recommend everyone to
            choose from this banner. Aki is ST DPS which excels in one shotting
            characters and she can do it very fast due to Mana Refund she gets
            on killing characters – allowing her to kill 3 characters with
            Main-Ultimate-Main combo in seconds. Aki also ages extremely well
            into endgame and is usable in the majority of guild raid content as
            optimal choice.
          </li>
          <li>
            If for whatever reason you do not want Aki, or maybe Aki banner is
            running normally, the next best choice would be Honglan. Honglan is
            classic AoE DPS which means she lacks the burst capability so your
            team would need overall well-rounded investment as she whittles
            them. But her unmatched speed debuffs and mana reduction
            capabilities allow your team to always skill first and makes winning
            much easier. She also scales well in endgame but she lacks usage in
            guild raids compared to Aki.
          </li>
        </ul>
        <p>
          The goal here is to use all your Everstones to get Aki to Origin in
          300 mileage. You won’t be able to do this right away, however you
          should have enough to do about 120~150 pulls right at the start. If
          you can manage about 4 copies of Aki in those pulls, you should be
          set.
        </p>
        <p>
          PS: It is totally doable to combine the two reroll choices for full
          reroll – that is Hunting Yuria in one of Pickup Choice Summon banners,
          but the lower rates for non-featured characters makes it significantly
          harder.
        </p>
        <p>
          <strong>TLDR</strong> - Get Daphne from Infinite Summon, Get Yuria
          spook from Standard Banner / Shards / Friendship Banner etc and choose
          Aki from Pick-Up Summon Choice and aim to get her Origin - for best
          possible start. Else Daphne+Aki steps for optimal start.
        </p>
        <h5>Keep or Reroll</h5>
        <p>
          If you don’t get the results you wanted, this would be the time to
          start the reroll. Go to the Settings menu, and click Withdraw from the
          bottom of the Account menu. You should now be able to relog in as a
          guest and try again!
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/reroll1.jpg"
          alt="Guide"
        />
        <p>
          If you’re satisfied with your account, your goals over the next 2
          weeks are as follows:
        </p>
        <ul>
          <li>
            Roll 300 mileage on Aki for the rest of the dupes, get her ascended
            to at least Origin. Clearing missions/ Gates/ Battlefronts will give
            you a reasonable amount of gems
          </li>
          <li>
            Clear as many battlefront and gate breakthrough stages as possible
          </li>
          <li>
            Clear as high as possible for Dual Gates/ Labyrinth/ Hall of
            Memories/ Current Event
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Eversoul | Prydwen Institute"
    description="How to reroll in Eversoul and start strong!"
    game="eversoul"
  />
);
